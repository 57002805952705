<template>
  <div>
    <HeaderPanel
      title="Branch group"
      :filter="filter"
      placeholder="Search Branch group"
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/setting/branch-group/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(status)="{ item }">
              <span
                :class="item.status == '1' ? 'text-success' : 'text-danger'"
                >{{ item.status == "1" ? "Active" : "Inactive" }}</span
              >
            </template>
            <template v-slot:cell(created_time)="{ item }">
              <div>{{ item.created_time | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ item.created_time | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(updated_time)="{ item }">
              <div>{{ item.updated_time | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ item.updated_time | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <div class="d-flex justify-content-center">
                <router-link :to="'/setting/branch-group/' + item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";

export default {
  components: {
    HeaderPanel,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-50",
        },
        {
          key: "sort_order",
          label: "Sort Order",
          class: "text-center w-auto",
        },

        { key: "actions", label: "Action", class: "w-auto" },
      ],
      items: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/Setting/GetBranchGroupList`,
        this.filter
      );
      this.rows = response.data.detail.count;
      this.items = response.data.detail.data;
      this.isBusy = false;
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
  },
};
</script>
